import { useRouter } from 'next/router';
import { SyntheticEvent, useState } from 'react';
import { useIntl } from 'react-intl';

import { useGlobalConfig } from '../../../hooks/configContext';
import { logUIEvent } from '../../../lib/analytics/analytics';
import UtmLocalStorage from '../../../lib/analytics/queryParams/UtmLocalStorage';
import {
  Cloud$Interest,
  Cloud$SuggestedAddress,
} from '../../../types/cloudApi';
import getFirstFromArray from '../../../utils/getFirstFromArray';

import { useAddressValidation } from './AddressValidation/useAddressValidation';
import { getMessageForFetchError } from './ErrorHandling/errorMessageHelpers';
import { useDestinationUrl } from './destinationUrl';
import { CreateInterestArgs } from './useCreateInterest';
import { Cloud$MainHardwareType } from '../../../types/cloudEnums';

export type SubmissionMethod = 'javascript' | 'nativeForm';

type AddressSearchFormProps = {
  address: Cloud$SuggestedAddress;
  children: React.ReactNode;
  createInterest: (args: CreateInterestArgs) => Promise<Cloud$Interest>;
  onError: (errorMsg: string) => void;
  onSuccess: (interest?: Cloud$Interest) => void;
  setAddress: (address: Cloud$SuggestedAddress) => void;
  submissionMethod: SubmissionMethod;
  suggestions: Array<Cloud$SuggestedAddress> | null;
  validateAddress: ReturnType<
    typeof useAddressValidation
  >['actions']['validate'];
  experimentVersion?: string;
  mainHardwareType?: Cloud$MainHardwareType;
};

/**
 * AddressSearchForm decides whether to submit the form natively (for iframes) or with JavaScript.
 *
 * @param {React.ReactNode} props.children - Child components to be rendered within the form
 * @param {function} props.handleSubmit - Function to handle form submission. Will only be used when submissionMethod="javascript".
 * @param {'javascript' | 'nativeForm'} props.submissionMethod - Determines the form submission method:
 *        - `javascript`: Uses JavaScript's fetch() to submit the form, This is the default mode we've been using since forever.
 *        - `nativeForm`: Uses native HTML form submission, providing a simpler and more traditional approach. Used for our iframes to avoid additional JavaScript to update the URL between frames.
 * @param {Cloud$SuggestedAddress | null} props.submittedAddress - The submitted address data or null if no address has been submitted
 */
const AddressSearchForm = ({
  children,
  address,
  onSuccess,
  onError,
  suggestions,
  submissionMethod,
  setAddress,
  createInterest,
  validateAddress,
  experimentVersion,
  mainHardwareType,
}: AddressSearchFormProps) => {
  const intl = useIntl();
  const config = useGlobalConfig();
  const { query: routerQuery, locale } = useRouter();
  const [utmData, setUtmData] = useState(null);
  const destinationUrl = useDestinationUrl();

  const handleSubmit = async (
    event: SyntheticEvent<HTMLFormElement, SubmitEvent>,
  ) => {
    event.preventDefault();
    setUtmData(UtmLocalStorage.retrieve());
    const form = event.currentTarget;

    const monthlyEnergyBill = Number(
      event.currentTarget.monthly_energy_bill?.value,
    );

    logUIEvent(
      {
        action: 'User clicked the check address button',
      },
      { broadcastToParent: true },
    );

    const validAddress = await validateAddress(
      address,
      suggestions,
      submissionMethod === 'javascript' ? 'client' : 'server',
    );

    if (!validAddress) {
      return;
    }
    // Update address field to indicate what address we're actually submitting
    setAddress(validAddress);

    if (submissionMethod === 'javascript') {
      try {
        const interest = await createInterest({
          validatedAddress: validAddress,
          monthlyEnergyBill,
          loanSimulationId: getFirstFromArray(routerQuery?.loanSimulationId),
          experimentVersion,
          locale,
          mainHardwareType,
        });
        onSuccess(interest);
      } catch (error) {
        onError(intl.formatMessage(getMessageForFetchError(error)));
      }
    }

    if (submissionMethod === 'nativeForm') {
      onSuccess();
      // Since we preventDefaulted at the top, this let us re-submit the form
      // after the address is validated.
      form.submit();
    }
  };

  if (submissionMethod === 'javascript') {
    return (
      <form
        onSubmit={handleSubmit}
        className="absolute w-full"
        data-testid="interest-registration"
      >
        {children}
      </form>
    );
  }

  // Native form
  return (
    <form
      action="/api/createLead"
      className="absolute w-full"
      data-testid="interest-registration"
      method="post"
      onSubmit={handleSubmit}
      target="_top"
    >
      <>
        <input
          type="hidden"
          name="next_url"
          id="next_url"
          value={destinationUrl}
        />
        <input type="hidden" name="address" id="address" value={address?.id} />
        <input
          type="hidden"
          name="address_string"
          id="address_string"
          value={address?.text}
        />
        <input
          type="hidden"
          name="business_unit"
          id="business_unit"
          value={config.BU_CONFIG.slug}
        />
        <input
          type="hidden"
          name="country"
          id="country"
          value={config.BU_CONFIG.market}
        />
        {!!mainHardwareType && (
          <input
            type="hidden"
            name="main_hardware_type"
            value={mainHardwareType}
          />
        )}
        {utmData && (
          <input
            type="hidden"
            name="utm_data"
            id="utm_data"
            value={JSON.stringify(utmData)}
          />
        )}
        <input
          type="hidden"
          name="external_loan_simulation_id"
          id="external_loan_simulation_id"
          value={getFirstFromArray(routerQuery?.loanSimulationId)}
        />
        <input
          type="hidden"
          name="preferred_locale"
          id="preferred_locale"
          value={locale}
        />
      </>

      {children}
    </form>
  );
};

export default AddressSearchForm;
